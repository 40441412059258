import {useState} from 'react';
import {useSpring, animated} from 'react-spring';

import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactForm = () => {

    const [submitHover, setSubmitHover] = useState(false);

    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [subject, setSubject] = useState("");
    const [content, setContent] = useState("");
    const [formError, setFormError] = useState(false);
    
    const [submitted, setSubmitted] = useState(false);

    let handleSubmit = async (e) => {
        setFormError(false);
        setSubmitted(true);
        e.preventDefault();
        try
        {
            const bod = JSON.stringify(
                {
                    email: email,
                    name: name,
                    subject: subject,
                    content: content,
                }
            );

            const head = new Headers(
                {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            );

            let res = await fetch("api/ContactFormFunction", {
                method: "POST",
                headers: head,
                body: bod,
            });


            if (res.status === 200)
            {
                setEmail("");
                setName("");
                setSubject("");
                setContent("");
                toast.success("Thank you, I will be in touch soon.");
                e.target.reset();
            }
            else
            {
                toast.error("Something went wrong: Status code "+res.status)
            }
        }
        catch (err)
        {
            toast.error("Error: "+err);
        }
        setSubmitted(false);
    }

    const subButtAnim = useSpring(
        {
            fontSize: submitHover?"2rem":"1.5rem",
        }
    );

    return ( 
        <div className='baseComponentBackground lg:p-16 p-8'>
            <h1 className="text-center text-4xl mb-10">Contact Me</h1>
            <form onSubmit={handleSubmit}
                  onInvalid={()=>setFormError(true)}
                  className="flex flex-col text-2xl space-y-5">

                <div className="flex flex-col md:flex-row justify-between md:space-x-10 md:space-y-0 space-y-5">

                    <div className="inline-block w-full">
                        <label className='whitespace-nowrap' htmlFor="Email">Your Email:</label>
                        <input className={"formText " + (formError?"invalid:border-red-600":"")}
                        type="email" name="email" id="Email" placeholder="your@email.com" required
                        onChange={(e)=>setEmail(e.target.value)}
                        value={email}/>
                    </div>
        
                    <div className="inline-block  w-full">
                        <label htmlFor="Name">Name:</label>
                        <input className={"formText " + (formError?"invalid:border-red-600":"")}
                        type="text" name="name" id="Name" placeholder="Name Here" required
                        onChange={(e)=>setName(e.target.value)}
                        value={name}/>
                    </div>

                </div>

                <div className="inline-block  w-full">
                    <label htmlFor="Subject">Subject:</label>
                    <input className={"formText " + (formError?"invalid:border-red-600":"")}
                    type="text" name="subject" id="Subject" placeholder="Subject Here" required
                    onChange={(e)=>setSubject(e.target.value)}
                    value={subject}/>
                </div>
                
                <div className="inline-block">
                    <label htmlFor="Content">Content</label>
                    <textarea className={"formText h-64 " + (formError?"invalid:border-red-600":"")}
                    type="text" name="content" id="" placeholder="Main Content Here" required
                    onChange={(e)=>setContent(e.target.value)}
                    value={content}/>
                </div>

                <h2 className='whiteTextShadow text-red-600 text-center'>{formError?"Please full the form with valid content":" "}</h2>
                <animated.button type="submit" style={subButtAnim}
                    onMouseOver={()=>setSubmitHover(true)}
                    onMouseOut={()=>setSubmitHover(false)}
                    disabled={submitted}
                    className={"w-fit m-auto px-10 py-5 rounded-md border-2 border-betterColors-darkerGreen "+(submitted?"bg-betterColors-midCyan":"bg-betterColors-ligherGreen")}>
                        Submit
                </animated.button>
            </form>


            {/* For toast notifications */}
            <ToastContainer
                position="top-center"
                autoClose={7000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                pauseOnHover
                toastStyle={
                    {
                        backgroundColor: "#0c1818",
                        color: "#fff"
                    }
                }/>
        </div>
     );
}
 
export default ContactForm;