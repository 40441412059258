import Sketch from 'react-p5'
import React from 'react'

const AnimBackground = () => {
    
    class Circ
    {
        constructor(pos, life, velocity)
        {
            this.pos = pos;
            this.life = life;
            this.velocity = velocity;
        }
    }

    let circs = []
    let oldPos;

    const setup = (p5, canvasParentRef) => {
        p5.createCanvas(p5.windowWidth, p5.windowHeight).parent(canvasParentRef);
        p5.background('#2d3a3aff');
        p5.strokeWeight(0);
        p5.fill('#465959');
        oldPos = new p5.createVector(0,0);
    }

    const draw = (p5) => {
        p5.background('#2d3a3aff');
        var newPos = new p5.createVector(p5.mouseX,p5.mouseY);
        if (newPos.dist(oldPos)>2)
        {
            circs.push(new Circ(newPos,40,new p5.createVector(newPos.x-oldPos.x,newPos.y-oldPos.y)));
        }

        oldPos = newPos;
        for (let i=circs.length-1;i>0;i--)
        {
            circs[i].pos.x+=circs[i].velocity.x/5;
            circs[i].pos.y+=circs[i].velocity.y/5;
            p5.circle(circs[i].pos.x, circs[i].pos.y, circs[i].life/1);

            circs[i].life--;
            if (circs[i].life<0)
            {
                circs.shift();
            }
        }
    }

    const resizeCanvas = (p5) => {
        p5.resizeCanvas(p5.windowWidth, p5.windowHeight);
    }      

    return (
        <Sketch className={"fixed -z-10 right-0 left-0 top-0 bottom-0"} setup={setup} draw={draw} windowResized={resizeCanvas}/>
    )
}
 
export default AnimBackground;