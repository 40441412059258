const ExtraInfo = () => {
    return ( 
        <div className="baseComponentBackground p-10 lg:px-36 text-2xl">
            <h1 className="text-center text-4xl mb-10">Extra Info</h1>

            <div className="flex flex-col md:flex-row justify-center md:justify-around text-center space-y-5 md:space-y-0">
                <div className="space-y-5 flex flex-col"> 
                    <p>Email: bosmanta@gmail.com</p>
                    <p>Phone: 071 202 8412</p>
                </div>
                <div className="space-y-5 flex flex-col underline">
                    <a href="https://github.com/BossManta" target="_blank" rel="noopener noreferrer">GitHub</a>
                    <a href="https://www.linkedin.com/in/timothy-bosman-0244a4182/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
                    <a href="./MyCV/MyCV.pdf" target="_blank" rel="noopener noreferrer">My CV</a>
                </div>
            </div>
        </div>
     );
}

export default ExtraInfo;