import React from 'react';
import {useState} from 'react';
import { Swiper, SwiperSlide} from 'swiper/react';
import { Autoplay, EffectCoverflow } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const IconCarousel = () => {

    const [slideNum, setSlideNum] = useState(0);

    const svgList = [
        {link: './Icons/dotnet.svg', text: 'Dotnet'},
        {link: './Icons/csharp.svg', text: "C#"},
        {link: './Icons/azure.svg', text: 'Azure'},
        {link: './Icons/css.svg', text: 'CSS'},
        {link: './Icons/html.svg', text: 'HTML'},  
        {link: './Icons/godot.svg', text: 'Godot'},
        {link: './Icons/gimp.svg', text: "Gimp"},
        {link: './Icons/git.svg', text: "Git"},
        {link: './Icons/github.svg', text: "Github"},
        {link: './Icons/js.svg', text: "JavaScript"},
        {link: './Icons/react.svg', text: "React"},
        {link: './Icons/tailwind.svg', text: "Tailwindcss"},
        {link: './Icons/vs.svg', text: "Visual Studio"},
        {link: './Icons/vscode.svg', text: "VS Code"},
        {link: './Icons/blender.svg', text: "Blender"},
        {link: './Icons/ASP.svg', text: "ASP.NET"},
        {link: './Icons/EFCore.svg', text: "EF Core"},
        {link: './Icons/python.svg', text: "Python"},
    ];

    return ( 
        <div className='baseComponentBackground text-center py-5 h-fit'>
            <h1 className="mb-10 text-4xl">Tool Experience</h1>
            <Swiper spaceBetween={10}
                    slidesPerView={3}
                    loop={true}
                    speed={1500}
                    centeredSlides={true}
                    onSlideChange={(s)=>{setSlideNum(s.realIndex)}}
                    autoplay={{
                    delay: 3500,
                    disableOnInteraction: false,
                    }}

                    effect={"coverflow"}
                    coverflowEffect={{
                        rotate: 10,
                        stretch: 0,
                        depth: 200,
                        modifier: 1,
                        slideShadows: false,
                    }}
                    
                    modules={[Autoplay, EffectCoverflow]}>

                {
                svgList.map((svg,ind)=>
                    <SwiperSlide key={ind} className='flex justify-center'>
                        <img className='bg-white p-4 rounded-xl border-4 border-betterColors-midCyan' width={200} height={200} src={svg.link} alt={svg.text} />
                    </SwiperSlide>
                )}
                <h1 className="pt-10 font-myFont text-3xl">{svgList[slideNum].text}</h1>
            </Swiper>
            
        </div>
     );
}
 
export default IconCarousel;