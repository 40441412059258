import { useState } from "react";
import About from "./PageComponents/About";
import Blog from "./PageComponents/Blog";


function App() {

  const [page, setPage] = useState("About");

  return (
    <>
      {page==="About"? <About setPage={setPage}/>:<Blog setPage={setPage}/>}
    </>
  );
}

export default App;
