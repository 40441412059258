import Navbar from "../Components/Navbar";
import MainHeading from "../Components/MainHeading";
import AnimBackground from "../P5/AnimBackground";
import MyGoal from "../Components/MyGoal";
import IconCarousel from "../Components/IconCarousel";
import ContactForm from "../Components/ContactForm";
import ExtraInfo from "../Components/ExtraInfo";

const About = ({setPage}) => {
    return ( 
        <div className="App bg-transparent">
            <Navbar setPage={setPage}/>

            <AnimBackground className={'-z-10 fixed top-0'}/>

            <MainHeading/>

            <div className="divBasicFormat">
                <MyGoal/>
            </div>

            <div className="divBasicFormat">
                <IconCarousel/>
            </div>

            <div className="divBasicFormat">
                <ContactForm/>
            </div>

            <div className="divBasicFormat">
                <ExtraInfo/>
            </div>

        </div>
     );
}
 
export default About;