import Typical from 'react-typical';

const MainHeading = () => {
    return (
        <div className='my-16 xl:mx-48 mx-10 rounded-md py-16 font-myTitleFont text-betterColors-ligherGreen bg-betterColors-darkCyan bg-opacity-80'>
            <Typical wrapper={'h1'} steps={['',500,'Timothy\n',1500,'Timothy\nBossManta',300,'Timothy\nBosman']} 
            className="md:text-7xl text-center text-5xl md:whitespace-normal whitespace-pre-wrap md:h-auto h-24"/>  
        </div>
    );
}
 
export default MainHeading;