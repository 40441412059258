import React from 'react'

const MyGoal = () => {
    return ( 
        <div className="font-myFont text-2xl text-white flex lg:flex-row flex-col">
            <div className="lg:p-16 p-8 lg:rounded-l-md lg:rounded-none rounded-t-md bg-betterColors-darkCyan bg-opacity-80">
                <h1 className="text-3xl mb-10">Who am I?</h1>
                <p className="mb-10">As the URL and title may suggest, my name is Timothy Bosman. I am an aspiring software/fullstack developer from South Africa.</p>
            </div>
            <div className='lg:p-16 p-8 lg:rounded-r-md lg:rounded-none rounded-b-md bg-betterColors-grayCyan'>
                <h1 className="text-3xl mb-10">My Current Objective:</h1>
                <p className="mb-10">My goal is to become a successful software developer. It has been a dream of mine ever since my early teenage years. My current objective is to gain both experience and confidence. I am fresh out of university, so I want to start gaining more practical experience. I am excited to apply the skills I have acquired during my degree in a more hands-on environment.</p>
                <p>During my studies, I accumulated skills in a variety of areas including data structures, algorithms, software engineering, and web programming. I am also excited to develop new skills and I am open to new experiences in my field. I am deeply passionate about programming and computers in general. I often spend my free time programming and experimenting. This includes personal coding projects, web development, and public game jams.</p>
            </div>           
        </div>
     );
}
 
export default MyGoal;