import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Navbar = ({setPage}) => {
    return (
        <div className="text-betterColors-ligherGreen font-bold bg-betterColors-midCyan
                        font-myFont text-2xl flex justify-between shadow-2xl py-5 sticky top-0 z-20 border-b-4 border-betterColors-darkCyan border-opacity-30">
            <p className="mx-10">
                Timothy Bosman
            </p>
            <ul className="flex mx-10">
                <li>
                    <button className="navbutt" onClick={()=>toast.info("My blog is still a W.I.P.")}>Blog</button>
                </li>
            </ul>
        </div>
    );
}
 
export default Navbar;
